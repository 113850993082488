import { SVGProps } from "react";

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={53}
    fill="none"
    {...props}
  >
    <ellipse cx={27.947} cy={26.261} fill="#D9D9D9" rx={27.926} ry={26.261} />
    <g clipPath="url(#a)">
      <path
        fill="#000"
        d="m33.351 30.344.727-4.405h-4.542v-2.858c0-1.205.634-2.38 2.668-2.38h2.065v-3.75s-1.874-.298-3.665-.298c-3.74 0-6.185 2.11-6.185 5.929v3.357H20.26v4.405h4.158v10.649h5.117V30.344h3.815Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M19.091 16.653h16.347v24.34H19.091z" />
      </clipPath>
    </defs>
  </svg>
);

export default FacebookIcon;
