import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54}
    height={57}
    fill="none"
    {...props}
  >
    <path
      fill="#20B1D0"
      d="M0 0h54v29.41c0 14.912-12.088 27-27 27s-27-12.088-27-27V0Z"
      opacity={0.4}
    />
  </svg>
);
export default SvgComponent;
