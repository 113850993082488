import {
  Container,
  HeadSection,
  TitleContainer,
  TitleSection,
  ImageContainer,
  HeadTitle,
  Para,
  TextContainer,
  ButtonContainer,
  CommentSection,
  EmailContainer,
  EmailTitle,
  Info,
  MCMTitle,
  LogoSection,
  FeaturePanel,
  WebIcon,
  MobileIcon,
} from "./style";
import Logo from "../../assets/images/logo.png";
import dots from "../../assets/images/dots.png";
import rings from "../../assets/images/rings.png";
import TitleImage from "../../assets/images/titleImage.png";
import LogoImage from "../../assets/images/LogoImage.png";
import BulbImage from "../../assets/images/BulbImage.png";
import FeaturedImage from "../../assets/images/mobile_featured_image.png";
import CategoriesImage from "../../assets/images/categories_image.png";
import SendIcon from "../../assets/svg/SendIcon";
import HomeDecor from "../../views/HomeDecor";
import MenApothecary from "../../views/ApothecarySection";
import BooksGifts from "../../views/BooksGifts";
// import VideoSection from "../../views/VideoContainer";
import FooterSection from "../../views/FooterSection";
import { useState } from "react";
import { toast } from "react-toastify";
import NavbarIcon from "../../assets/svg/NavbarIcon";
import MobileNavbarIcon from "../../assets/svg/MobileNavbarIcon";

const HomePage = () => {
  const notify = (msg: string) => toast.error(msg);
  const [formData, setFormData] = useState<any>({
    name: "NA",
    email: "",
    phone: "NA",
    address: "NA",
    processing: false,
  });
  const submitForm = () => {
    var formdata = new FormData();

    console.log(formData);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData?.email) {
      notify("Please enter email");
      return;
    } else if (!emailRegex.test(formData?.email)) {
      notify("Please enter a valid email For eg. john@gmail.com");
      return;
    }

    formdata.append("email", formData?.email);
    formdata.append("name", formData?.name);
    formdata.append("phone", formData?.phone);
    formdata.append("address", formData?.address);
    formdata.append("subject", "New Contact Requesr");
    formdata.append("send", "true");
    setFormData({
      ...formData,
      processing: true,
    });
    fetch("/api/send.php", {
      method: "POST",
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.text())
      .then((result) => {
        setFormData({
          name: "NA",
          email: "",
          phone: "NA",
          address: "NA",
          processing: false,
        });
        toast.success(
          "We have received your information, Thanks for contacting us. We will be in touch very soon."
        );
      })
      .catch((error) => {
        setFormData({
          ...formData,
          processing: false,
        });
        toast.error("Mail Server Error: " + error)?.toString();
      });
  };
  const handlechange = (e: any, key: string) => {
    setFormData({
      ...formData,
      [key]: e?.target?.value || "",
    });
  };
  return (
    <Container>
      <WebIcon>
        <NavbarIcon />
      </WebIcon>
      <MobileIcon>
        <MobileNavbarIcon />
      </MobileIcon>
      <HeadSection>
        <img src={rings} alt="logo" />
        <img src={dots} alt="logo" />
      </HeadSection>
      <TitleContainer>
        <TextContainer>
          <TitleSection>
            <MCMTitle>MCM AVENUE</MCMTitle>
            <HeadTitle>Beyond imagination</HeadTitle>
          </TitleSection>
          <Para>
            MCM will turn ordinary ideas into extraordinary visual experiences
          </Para>
        </TextContainer>
        <ImageContainer>
          <img width={"100%"} src={TitleImage} alt="logo" />
          <EmailContainer>
            <EmailTitle>Be the first to know</EmailTitle>
            <Info>
              <input
                placeholder="Enter Your Email"
                value={formData?.email}
                className="input_email"
                onChange={(e) => {
                  handlechange(e, "email");
                }}
              />
              <button
                style={
                  formData?.processing
                    ? {
                        opacity: 0.3,
                        border: "none",
                        backgroundColor:"transparent"

                      }
                    : {
                        border: "none",
                        backgroundColor:"transparent"
                      }
                }
                disabled={formData?.processing}
                onClick={() => {
                  submitForm();
                }}
                type="submit"
              >
                <SendIcon />
              </button>
            </Info>
          </EmailContainer>
        </ImageContainer>
      </TitleContainer>
      <LogoSection>
        <img src={LogoImage} alt="logo" />
      </LogoSection>
      <LogoSection>
        <img src={BulbImage} alt="logo" className="web-featured-image" />
        <img src={FeaturedImage} alt="logo" className="mobile-featured-image" />
        <FeaturePanel>
          <img src={CategoriesImage} alt="featured-categories" />
        </FeaturePanel>
      </LogoSection>
      <HomeDecor />
      <MenApothecary />
      <BooksGifts />
      {/* <VideoSection /> */}
      <FooterSection />
    </Container>
  );
};

export default HomePage;
