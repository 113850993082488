import { SVGProps } from "react";

const SendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={65}
    height={62}
    fill="none"
    {...props}
  >
    <rect
      width={62.77}
      height={59.823}
      x={0.641}
      y={0.641}
      fill="#FF6201"
      stroke="#C4C4C4"
      strokeWidth={1.281}
      rx={12.17}
    />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M50.234 12.608c.733.485 1.117 1.322.98 2.16l-4.645 28.795a2.216 2.216 0 0 1-1.16 1.592 2.426 2.426 0 0 1-2.032.11l-9.164-3.633-2.91 5.157c-.377.671-1.183 1.01-1.959.823-.776-.187-1.32-.851-1.32-1.613v-6.645c0-.366.13-.726.37-1.017L40.388 23.62c.18-.492-.472-.99-.944-.582L26.456 34.246l-2.322 2a2.406 2.406 0 0 1-2.452.402l-6.167-2.45c-.813-.326-1.365-1.06-1.415-1.904-.051-.844.399-1.64 1.168-2.063l32.506-17.72a2.424 2.424 0 0 1 2.467.097h-.007Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M14.092 12.22h37.15v35.44h-37.15z" />
      </clipPath>
    </defs>
  </svg>
);

export default SendIcon;
