import {
  FeatureSlideSection,
  SlideImage,
  HomeDecorSection,
  SliderContainer,
  BooksSliderTextContainer,
  SliderTitle,
  SliderText,
  MoreText,
} from "../pages/HomePage/style";
import Slider from "react-slick";
import decor from "../assets/images/books_image.png";
import gifts from "../assets/images/gifts_image.png";
import book from "../assets/images/booksImage.png";
import PrevArrow from "../assets/images/back_arrow.png";
import NextArrow from "../assets/images/next_arrow.png";

const BooksGifts = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1704,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: (
      <img src={NextArrow} alt="prev-arrow" />
    ),
    prevArrow: (
      <img src={PrevArrow} alt="prev-arrow" />
    )
  };
  return (
    <SliderContainer>
      <HomeDecorSection>
        <BooksSliderTextContainer>
          <SliderTitle>Books and Gifts</SliderTitle>
          <SliderText>Where Stories Come to Life</SliderText>
          <MoreText>More</MoreText>
        </BooksSliderTextContainer>
        <FeatureSlideSection>
          <Slider {...settings}>
            <SlideImage>
              <img src={decor} alt="slider" />
            </SlideImage>
            <SlideImage>
              <img src={gifts} alt="slider" />
            </SlideImage>
            <SlideImage>
              <img src={decor} alt="slider" />
            </SlideImage>
            <SlideImage>
              <img src={book} alt="slider" />
            </SlideImage>
            <SlideImage>
              <img src={gifts} alt="slider" />
            </SlideImage>
            <SlideImage>
              <img src={book} alt="slider" />
            </SlideImage>
          </Slider>
        </FeatureSlideSection>
      </HomeDecorSection>
    </SliderContainer>
  );
};

export default BooksGifts;
