import styled from "styled-components";
import TitleImage from "../../assets/images/titleImage.png";

export const Container = styled.div`
  width: 100%;
  background: #fffaf5;
  min-height: 100vh;
  position: relative;
  input {
    outline: none;
  }
  svg {
    // height: 112px;
    // position: absolute;
    // right: 30%;
    // width: 110px;
  }
`;
export const WebIcon = styled.div`
  svg {
    height: 112px;
    position: absolute;
    right: 15%;
    width: 110px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
export const MobileIcon = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    svg {
      position: absolute;
      right: -10%;
    }
  }
`;
export const HeadSection = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 10px;
  padding-top: 70px;
`;
export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 0 50px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 50px;
  @media (max-width: 767px) {
    padding: 0 26px;
    gap: 0;
  }
`;

export const MCMTitle = styled.div`
  font-family: Poetsen One;
  font-size: 125px;
  font-weight: 800;
  color: #00a7cb;
  letter-spacing: 3px;
  line-height: 120px;
  text-shadow: 1px 1px 2px #000000;
  @media (max-width: 767px) {
    font-size: 88px;
    line-height: 68px;
  }
`;
export const HeadTitle = styled.div`
  font-family: Playfair Display;
  font-size: 55px;
  font-weight: 800;
  color: #ff8337;
  max-width: 760px;
  letter-spacing: 3px;
  text-shadow: 1px 3px 2px #000000;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 33px;
  }
`;
export const LogoSection = styled.div`
  img {
    width: 100%;
    max-width: 100vw;
  }
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    .web-featured-image {
      display: none;
    }
    margin-top: 50px;
    img {
      min-height: 160px;
    }
  }
  .mobile-featured-image {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
`;
export const FeaturePanel = styled.div`
  img {
    position: absolute;
    max-width: 718px;
    width: 100%;
    min-height: 449px;
    top: 11%;
  }
  @media (max-width: 767px) {
    img {
      max-width: 282px;
      min-height: 187px;
      top: 53%;
    }
  }
`;

export const TitleSection = styled.div`
  font-family: "Playfair Display", serif;
  font-size: 90px;
  font-weight: 800;
  color: #000958;
  max-width: 760px;
  line-height: 100%;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;
export const Para = styled.div`
  font-family: "Roboto", serif;
  font-size: 26px;
  color: #70798b;
  margin: 30px 0 50px 0;
  max-width: 600px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 25px;
    margin: 30px 0 30px 0;
  }
`;
export const ButtonContainer = styled.div`
  background: #ff6201;
  font-family: "Poppins", serif;
  border-radius: 10px;
  max-width: 200px;
  text-align: center;
  padding: 20px;
  color: #fffaf5;
  border: 1.5px solid #c4c4c4;
`;

export const TextContainer = styled.div`
  display: grid;
  max-width: 550px;
`;
export const ImageContainer = styled.div`
  position: relative;
  // background-image: ${TitleImage}
  max-width: 700px;
  max-height: 650px;
`;
export const EmailContainer = styled.div`
  position: absolute;
  bottom: 30px;
  right: 20px;
  background: #e3f1f0;
  padding: 13px;
  width: 100%;
  max-width: 300px;
  border-radius: 20px;

  .input_email {
    padding: 21px;
    min-width: 200px;
    background: none;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
  }
  @media (max-width: 767px) {
    position: unset;
    max-width: 360px;
    border-radius: 30px;
    .input_email {
      min-width: 230px;
      padding: 17px;
    }
  }
`;
export const Info = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 15px;
  /* @media (max-width: 767px) {
    button {
      width: 50px;
      height: 48px;
    }
    svg{
      rect{
        width: 50px;
        height: 48px;
      }
 
}
  } */
`;
export const EmailTitle = styled.div`
  color: #2f2f2f;
  font-family: "Roboto", serif;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const CommentSection = styled.div`
  background: #ff6201e3;
  padding: 50px;
  border-radius: 20px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 100px;
  overflow: hidden;
`;
export const CommentContainer = styled.div`
  background: #fffaf5;
  width: 100%;
  margin: 0 auto;
  padding: 30px 50px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
`;
export const LampImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  margin-top: -190px;
`;
export const CommentText = styled.div`
  font-family: "Roboto", serif;
  font-weight: 400;
  margin-top: 30px;
  color: #6a6a6a;
  line-height: 150%;
`;
export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const Name = styled.div`
  display: grid;
  align-items: center;
  gap: 5px;
`;
export const Title = styled.div`
  font-family: poppins;
  color: #030314;
  font-weight: bold;
`;
export const Designation = styled.div`
  font-family: Roboto;
  color: #625d69;
`;
export const HeadLabelSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FeatureContainer = styled.div`
  background: #12a0bb45;
  width: 100%;
  padding: 70px 50px;
  margin-top: 50px;
  min-height: 700px;
`;

export const FeatureTitleSection = styled.div`
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #fcf8f3 50%);
  width: 100%;
  padding: 50px 100px;
  gap: 20px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 60px;
  overflow: hidden;
  @media (max-width: 1270px) {
    display: grid;
  }
`;
export const FeatureTitle = styled.div`
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 65px;
  line-height: 110%;
  max-width: 400px;
  color: #3a3a3a;
  @media (max-width: 1270px) {
    font-size: 30px;
    line-height: 33px;
  }
`;
export const FeatureTextContainer = styled.div`
  display: grid;
  align-content: center;
  gap: 30px;
`;
export const SlideImage = styled.div`
  position: relative;
  z-index: 0;
  border-radius: 10px;
  img {
    height: 507px;
  }
`;
export const ImageNumber = styled.div`
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  color: #616161;
`;
export const ImageTitle = styled.div`
  position: absolute;
  bottom: 50px;
  left: 10%;
  padding: 20px;
  width: 100%;
  max-width: 200px;
  z-index: 1;
  background: #ffffffb8;
  font-family: poppins;
  font-weight: 600;
  font-size: 20px;
`;

export const BuyButtonContainer = styled.div`
  background: #ff6201;
  font-family: "Poppins", serif;
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  color: #ffffff;
  border: 1.5px solid #c4c4c4;
  max-width: 150px;
`;
export const FeatureSlideSection = styled.div`
  width: 100%;
  max-width: 1060px;
  img {
    width: 100%;
    min-width: 320px;
    max-width: 330px;
    height: 490px;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 20px;
    @media (max-width: 767px) {
      max-width: 258px;
      min-width: 258px;
      max-height: 224px;
      margin-top: 0;
    }
  }

  .slick-dots li.slick-active button:before {
    color: #e74c3c;
    border: 1px solid #e74c3c;
    border-radius: 50%;
  }
  .slick-prev {
    position: absolute;
    z-index: 2;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    left: -289px;
    top: 327px;
  }
  .slick-next {
    position: absolute;
    z-index: 2;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    left: -236px;
    top: 327px;
  }
  .slick-prev:before {
    padding: 10px;
    box-shadow: 0px 4px 14px 1px #000009;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
    color: #000000;
    z-index: 2;
  }
  .slick-next:before {
    padding: 10px;
    z-index: 2;
    box-shadow: 0px 4px 14px 1px #000009;
    color: #000000;
    border-radius: 50%;
    z-index: 2;
  }
  @media (max-width: 767px) {
    .slick-next {
      position: absolute;
      right: 35px;
      z-index: 2;
      min-width: 20px;
      max-width: 20px;
      height: 20px;
      left: -157px;
      top: 147px;
    }
    .slick-prev {
      position: absolute;
      z-index: 2;
      min-width: 20px;
      max-width: 20px;
      height: 20px;
      left: -184px;
      top: 147px;
    }
  }
`;
export const SliderContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;
export const HomeSliderTextContainer = styled.div`
  display: grid;
  align-content: center;
  background: #12a0bb;
  padding: 100px;
  border-radius: 0px 16px 16px 0px;
  max-height: 500px;
  min-width: 500px;
  max-width: 550px;
  margin: auto 20px;
  @media (max-width: 767px) {
    max-width: 258px;
    min-width: 258px;
    max-height: 224px;
    border-radius: 0px 7px 7px 0px;
    margin: 0;
    padding: 20px;
  }
`;
export const ApothecarySliderTextContainer = styled.div`
  display: grid;
  align-content: center;
  background: #ff6201f0;
  padding: 100px;
  border-radius: 0px 16px 16px 0px;
  max-height: 500px;
  min-width: 500px;
  max-width: 550px;
  margin: auto 20px;
  @media (max-width: 767px) {
    max-width: 258px;
    min-width: 258px;
    max-height: 224px;
    border-radius: 0px 7px 7px 0px;
    margin: 0;
    padding: 20px;
  }
`;
export const BooksSliderTextContainer = styled.div`
  display: grid;
  align-content: center;
  background: #ffba02f5;
  padding: 100px;
  border-radius: 0px 16px 16px 0px;
  max-height: 500px;
  min-width: 500px;
  max-width: 550px;
  margin: auto 20px;
  @media (max-width: 767px) {
    max-width: 258px;
    min-width: 258px;
    max-height: 224px;
    border-radius: 0px 7px 7px 0px;
    margin: 0;
    padding: 20px;
  }
`;
export const HomeDecorSection = styled.div`
  width: 100%;
  padding: 50px 70px;
  gap: 20px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 60px;
  overflow: hidden;
  @media (max-width: 1270px) {
    display: grid;
  }
  @media (max-width: 767px) {
    display: flex;
    padding: 0 20px;
    gap: 10px;
  }
`;
export const SliderTitle = styled.div`
  font-family: "Playfair Display", serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 57.89px;
  color: #fdfbf8;
  max-width: 230px;
  @media (max-width: 767px) {
    font-size: 21px;
    line-height: 25px;
  }
`;
export const SliderText = styled.div`
  font-family: "Roboto", serif;
  font-size: 18px;
  font-weight: 400;
  color: #fdfbf8;
  margin-top: 20px;
  max-width: 220px;
  @media (max-width: 1270px) {
    font-size: 11px;
    line-height: 10px;
    margin-top: 14px;
  }
`;
export const MoreText = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 20px;
  cursor: pointer;
  @media (max-width: 1270px) {
    font-size: 8px;
    line-height: 7px;
  }
`;

export const VideoContainer = styled.div`
  img {
    width: 100vw;
  }
`;
export const FooterContainer = styled.div`
  width: 100%;
  padding: 50px;
  background: #e3f1f0;
  display: flex;
  margin-top: 200px;
  position: relative;
  @media (max-width: 1270px) {
    flex-wrap: wrap-reverse;
  }
  input {
    padding: 20px 15px;
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    background: none;
    border: 1px solid #c4c4c4;
    border-radius: 12px;
  }
  @media (max-width: 767px) {
    input {
      padding: 20px 15px;
      width: 100%;
      min-width: unset;
      max-width: 300px;
      background: none;
      border: 1px solid #c4c4c4;
      border-radius: 12px;
    }
  }
`;
export const FormSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    max-width: 300px;
  }
`;
export const DataSection = styled.div`
  display: grid;
  gap: 20px;
`;
export const ContactSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    max-width: 300px;
    align-items: flex-start;
    margin-top: 20px;
  }
`;
export const AddressSection = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 10px;
  max-width: 180px;
`;
export const AddressTitle = styled.div`
  color: #6a6a6a;
  font-family: "Roboto", serif;
  font-size: 16px;
  font-weight: 600;
`;
export const AddressText = styled.div`
  color: #6a6a6a;
  font-family: "Roboto", serif;
  font-size: 14px;
  line-height: 180%;
  div {
    width: 100%;
    white-space: nowrap;
  }
  span {
    font-weight: bold;
  }
`;
export const FooterTitle = styled.div`
  color: #2f2f2f;
  font-family: "Roboto", serif;
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;
export const SocialSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
export const SocialIcon = styled.div`
  cursor: pointer;
`;
export const Line = styled.div`
  width: 80vw;
  height: 1px;
  background: #000000;
`;
export const Copyright = styled.div`
  color: #6a6a6a;
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
`;
export const FooterPanel = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 150px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 60px;
  }
`;
export const FooterImage = styled.div`
  position: absolute;
  top: -120px;
  right: 100px;
  @media (max-width: 1270px) {
    position: relative;
    top: -170px;
    left: 100px;
  }

  img {
    max-width: 600px;
    height: 400px;
  }
  @media (max-width: 767px) {
    left: -15px;
  }
  img {
    max-width: 324px;
    height: 282px;
  }
`;
