import {
  FooterContainer,
  FormSection,
  DataSection,
  FooterImage,
  ContactSection,
  FooterTitle,
  AddressSection,
  AddressTitle,
  AddressText,
  SocialSection,
  SocialIcon,
  Line,
  Copyright,
  FooterPanel,
} from "../pages/HomePage/style";
import rings from "../assets/images/footer_image.png";
import SendIcon from "../assets/svg/SendIcon";
import FacebookIcon from "../assets/svg/FacebookIcon";
import LinkedinIcon from "../assets/svg/LinkedinIcon";
import TwitterIcon from "../assets/svg/TwitterIcon";
import InstagramIcon from "../assets/svg/InstagramIcon";
import { useEffect, useState } from "react";
import { EventType } from "@testing-library/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FooterSection = () => {
const [formData,setFormData]=useState<any>({
  name:"",
  email:"",
  phone:"",
  address:"",
  processing:false
})
const notify = (msg:string) => toast.error(msg);
const submitForm=()=>{
    var formdata = new FormData();
    console.log(formData);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  
     if(!formData?.name){
      notify("Please enter name")
      return
    }
    else if(!formData?.email){
      notify("Please enter email")
      return
    }
    else if (!emailRegex.test(formData?.email)) {
      notify("Please enter a valid email For eg. john@gmail.com")
      return
    }
    else if(!formData?.phone){
      notify("Please enter contact no")
      return
      
    }
   else if (!/^(\+?\d){7,12}$/.test(formData?.phone)) {
    notify("Invalid contact no")
    return
    }
    else if(!formData?.address){
      notify("Please enter address")
      return
    }
 
    formdata.append("email", formData?.email);
    formdata.append("name", formData?.name);
    formdata.append("phone",formData?.phone);
    formdata.append("address", formData?.address);
    formdata.append("subject", "New Contact Requesr");
    formdata.append("send", "true");
    setFormData({
      ...formData,
      processing:true
    })
    fetch("/api/send.php", {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result =>{
        setFormData({
          name:"",
          email:"",
          phone:"",
          address:"",
          processing:false
        })
        toast.success("We have received your information, Thanks for contacting us. We will be in touch very soon.")
      })
      .catch(error => {
        setFormData({
         ...formData,
          processing:false
        })
        toast.error("Mail Server Error: "+error)?.toString()
      });
}
const handlechange=(e:any,key:string)=>{
  setFormData({
    ...formData,
    [key]:e?.target?.value||""
  })
}

  return (
    <FooterContainer>
      
       <ToastContainer />
      <DataSection>
      <FooterTitle>Subscribe to Newsletter</FooterTitle>

        <>
        <form action="javascript:void(0)" onSubmit={()=>{
          submitForm()
        }} >
      <FormSection>
   <input value={formData?.name} placeholder="Enter Your Name" 
    onChange={(e)=>{
      handlechange(e,"name")
    }} 
   className="input_form" />
   <input value={formData?.email}  onChange={(e)=>{
      handlechange(e,"email")
    }}  placeholder="Enter Your e-mail" className="input_form" />
   </FormSection>
   <ContactSection>
   <input value={formData?.phone}   placeholder="Contact Number " onChange={(e)=>{
      handlechange(e,"phone")
    }}  className="input_form" />
   <input  value={formData?.address}  placeholder="Address" onChange={(e)=>{
      handlechange(e,"address")
    }}  className="input_form" />
   <button  style={formData?.processing?{
    opacity:0.3,
    border:"none",
    backgroundColor:"transparent"
    
   }:{
    border:"none",
    backgroundColor:"transparent"
   }} disabled={formData?.processing} type="submit"><SendIcon /></button>
   
  
   </ContactSection>
  
   </form>
   </>
        <FooterPanel>
          <AddressSection>
            <AddressTitle>Store Hours</AddressTitle>
            <AddressText>
              {" "}
              <div>
                <span>MON-WED only by appointment</span>
              </div>{" "}
              <div>
                Thursday <span>11AM - 7PM</span>{" "}
              </div>{" "}
              <div>
                {" "}
                Friday <span>11AM - 7PM</span>{" "}
              </div>
              <div>
                Saturday <span>11AM - 7PM</span>
              </div>
              <div>
                {" "}
                Sunday <span>11AM - 7PM</span>
              </div>
            </AddressText>
          </AddressSection>
          <AddressSection>
            <AddressTitle>Address</AddressTitle>
            <AddressText> 1131 56th street delta, BC V4L 2A2</AddressText>
          </AddressSection>
        </FooterPanel>
        <SocialSection>
          <SocialIcon>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=61552055990131&mibextid=LQQJ4d">
              <FacebookIcon  />
            </a>
          </SocialIcon>
          {/* <SocialIcon>
           <TwitterIcon/>
          </SocialIcon>
          <SocialIcon>
            <InstagramIcon /> */}
          {/* </SocialIcon> */}
        </SocialSection>
        <Line />
        <Copyright>Copyright 2023 MCM AVENUE. All Rights Reserved.</Copyright>
      </DataSection>
      <FooterImage>
        <img src={rings} alt="logo" />
      </FooterImage>
    </FooterContainer>
  );
}

export default FooterSection;
